import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ChevronLeft } from 'react-feather';
import PropTypes from 'prop-types';

const BackBtn = ({ route }) => {
  const history = useHistory();
  const goRoute = () => history.push(route);

  return (
    <>
      <Button onClick={goRoute} variant="link" size="sm">
        <ChevronLeft size={32} />
      </Button>
    </>
  );
};

BackBtn.propTypes = {
  route: PropTypes.string
};

BackBtn.defaultProps = {
  route: '/'
};
export default BackBtn;
