import React from 'react';
import { Field, getIn } from 'formik';
import { Card, Col, Row } from 'react-bootstrap';

import { BsPlusCircleFill } from 'react-icons/bs';
import { FormikInput, UploadImage } from '../../components';

const UserForm = props => {
  const { errors, touched, setFieldValue, fromShow, user } = props;
  return (
    <>
      <Col
        md={fromShow ? { span: 2, offset: 1 } : 12}
        className={`mb-3 ${fromShow && 'img-align-center'}`}
      >
        {fromShow ? (
          <Card.Img src={user.avatarInfo?.fileUrl || ''} />
        ) : (
          <Field name="user[avatar]">
            {({ field }) => (
              <UploadImage
                {...field}
                name={user.avatarInfo?.filename}
                imageUrl={getIn(user.avatarInfo, 'fileUrl')}
                onChange={_avatar => setFieldValue(field.name, _avatar)}
                helpText="Formato sugerido 620x400px de máximo 10mb."
              />
            )}
          </Field>
        )}
      </Col>
      <Col md={{ span: 8, offset: 2 }} className="mt-3">
        <Row>
          <Col md={6}>
            <Field name="user[fullName]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Nombre"
                  disabled={fromShow}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="user[email]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  disabled
                  label="Email"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="user[address]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Dirección Completa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default UserForm;
