import ApiService from '../services/apiService';

// eslint-disable-next-line import/prefer-default-export
export const createRedeemRequest = (
  productId,
  { params, dispatch, failureCallback, successCallback }
) =>
  ApiService.request('post', `/redeems?product_id=${params.productId}&address=${params.address}`, {
    dispatch,
    failureCallback,
    successCallback
  });
export const indexRedeemsRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/redeems${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};