import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { validateToken } from '../../actions/auth';
import { updatePasswordRequest } from '../../requests/user';
import UserChangePasswordForm from './UserChangePasswordForm';

const UserChangePassword = props => {
  const { user } = {
    ...props,
    password: '',
    currentPassword: '',
    passwordConfirmation: ''
  };
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFailureRequest = error => {
    dispatch(
      sendAlert({ kind: 'error', message: error?.response?.data?.message })
    );
  };

  const handleSuccessUpdate = () => {
    dispatch(validateToken());
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Contraseña actualizada con éxito'
      })
    );
    history.push('/profile');
  };

  const handlePasswordUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    updatePasswordRequest(user.id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col md={{ span: 8, offset: 1 }}>
          <h2 className="text-uppercase">Cambiar Contraseña</h2>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <UserChangePasswordForm
            user={user}
            formRequest={handlePasswordUpdateRequest}
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  const user = state.auth;
  return user;
};

export default connect(mapStateToProps)(UserChangePassword);
