import React from 'react';
import { Badge } from 'react-bootstrap';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = ({
  toggled,
  handleToggleSidebar,
  unreadChallenges,
  unreadNews
}) => {
  const url = useLocation();
  return (
    <ProSidebar
      onToggle={handleToggleSidebar}
      breakPoint="md"
      toggled={toggled}
      width={216}
    >
      <>
        <Menu iconShape="square">
          <MenuItem
            active={url.pathname.includes('/home')}
            suffix={<Badge variant="primary">{unreadChallenges || null}</Badge>}
          >
            Inicio
            <Link to="/home" />
          </MenuItem>
          <MenuItem
            active={url.pathname.includes('/challenges')}
            suffix={<Badge variant="primary">{unreadChallenges || null}</Badge>}
          >
            Desafíos
            <Link to="/challenges" />
          </MenuItem>
          <MenuItem active={url.pathname.includes('/products')}>
            Premios
            <Link to="/products" />
          </MenuItem>
          <MenuItem active={url.pathname.includes('/profile')}>
            Perfil
            <Link to="/profile" />
          </MenuItem>
          <MenuItem
            active={url.pathname.includes('/news')}
            suffix={<Badge variant="primary">{unreadNews || null}</Badge>}
          >
            Noticias
            <Link to="/news" />
          </MenuItem>
          <MenuItem active={url.pathname.includes('/ranking')}>
            Ranking
            <Link to="/ranking" />
          </MenuItem>
        </Menu>
      </>
    </ProSidebar>
  );
};

export default Sidebar;
