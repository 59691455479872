import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './app/config/store';
import App from './App';
import { AbilityContext } from './app/config/abilityContext';
import ability from './app/config/ability';
import './index.scss';

const app = (
  <Provider store={store}>
    <AbilityContext.Provider value={ability}>
      <App />
    </AbilityContext.Provider>
  </Provider>
);
ReactDOM.render(app, document.getElementById('root'));
