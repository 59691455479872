import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';

import {
  indexProductsRequest,
  indexProductCategoriesRequest
} from '../../requests/products';
import { BasicDropdown, Slides, MultiSelect } from '../../components';
import { useDebounce } from '../../services/utils';
import { indexSlidesRequest } from '../../requests/slides';
import ProductsList from './ProductsList';
import baseColors from '../../assets/stylesheets/base_colors';

const sortColumns = [
  {
    key: 1,
    title: 'Precio: ascendente',
    sortColumn: 'price',
    sortDirection: 'asc'
  },
  {
    key: 2,
    title: 'Precio: descendente',
    sortColumn: 'price',
    sortDirection: 'desc'
  },
  {
    key: 3,
    title: 'Nombre: ascendente',
    sortColumn: 'name',
    sortDirection: 'asc'
  },
  {
    key: 4,
    title: 'Nombre: descendente',
    sortColumn: 'name',
    sortDirection: 'desc'
  }
];

const ProductIndex = () => {
  const [products, setProducts] = useState([]);
  const [slides, setSlides] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useDebounce(searchString, 500);
  const [sortBy, setSortBy] = useState(sortColumns[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        zone: 2
      },
      successCallback: result => {
        setSlides(camelCaseRecursive(result.data.data));
      }
    });

    indexProductCategoriesRequest({
      dispatch,
      successCallback: result => {
        setCategories([
          ...camelCaseRecursive(result.data.data).map(e => ({
            value: e.id,
            label: e.name
          }))
        ]);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const params = {
      actives: true,
      sort_direction: sortBy.sortDirection,
      sort_column: sortBy.sortColumn
    };
    if (category) params.product_categories = category;
    if (debouncedSearchString) params.query = debouncedSearchString;
    setLoading(true);

    indexProductsRequest({
      dispatch,
      params,
      successCallback: result => {
        setLoading(false);
        setProducts(camelCaseRecursive(result.data.data));
      }
    });
  }, [dispatch, sortBy, category, debouncedSearchString]);

  const handleOnChangeSelect = selected => {
    if (selected && selected.length) {
      setCategory(selected.map(a => a.value));
    } else {
      setCategory(null);
    }
  };

  const stylesOptionsSelect = {
    control: styles => {
      return {
        ...styles,
        borderRadius: 15,
        backgroundColor: baseColors.bgSelect,
        boxShadow: '0 0 0 0px #6c757d',
        borderColor: baseColors.gray600,
        ':active': {
          borderRadius: 15,
          borderColor: baseColors.gray600,
          boxShadow: '0 0 0 1px #6c757d'
        },
        '&:hover': {
          borderColor: baseColors.gray600,
          boxShadow: '0 0 0 1px #6c757d'
        },
        ':focus': {
          borderColor: baseColors.gray600,
          boxShadow: '0 0 0 1px #6c757d'
        }
      };
    },
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: baseColors.bgMultiValueSelect,
        color: 'white !important',
        fontWeight: 'bold',
        borderRadius: 15,
        paddingInline: 10
      };
    },
    multiValueLabel: styles => ({
      ...styles,
      color: 'white'
    }),
    multiValueRemove: styles => ({
      ...styles,
      ':hover': {
        backgroundColor: baseColors.hoverBgMultiValueRemoveSelect,
        color: baseColors.hoverTextMultiValueRemoveSelect
      }
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: 14,
      color: '#bdbbbb'
    }),
    menuList: styles => ({
      ...styles,
      fontSize: 14,
      color: baseColors.gray600,
      fontWeight: 'bold'
    })
  };

  return (
    <>
      <Slides
        items={slides}
        carouselStyle={{
          minHeight: 170
        }}
      />
      <Row>
        <Col>
          <h2 id="nn-slides-title" className="screen-title">
            Premios
          </h2>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs={12} sm={7} className="product-multiselect-container">
          <MultiSelect
            options={categories}
            handleOnChange={handleOnChangeSelect}
            placeholder="CATEGORÍAS"
            styles={stylesOptionsSelect}
          />
        </Col>
        <Col
          xs={12}
          sm={{ span: 4, offset: 1 }}
          className="product-order-container"
        >
          <BasicDropdown
            variant="outline-gray"
            active={sortBy.key}
            onChange={setSortBy}
            items={sortColumns}
            titleDrop="ORDENAR POR"
            containerStyle={{
              textAlign: 'end'
            }}
            buttonStyle={{
              width: '100%',
              borderRadius: 15
            }}
          />
        </Col>
      </Row>
      <ProductsList products={products} loading={loading} />
    </>
  );
};

export default withRouter(connect()(ProductIndex));
