import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const NewsCard = ({ item }) => (
  <div key={item.id} className="card border-light news-container my-3">
    <img src={item.fileInfo.fileUrl} className="card-img-top" alt="..." />
    <div className="card-body">
      <h5 className="card-title">{item.title}</h5>
      <p className="card-body-text">{item.lede}</p>
    </div>
    <div className="see-more">
      <a href={`/news/${item.id}`}>Ver Más..</a>
    </div>
  </div>
);

NewsCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    fileInfo: PropTypes.shape({
      fileUrl: PropTypes.string,
      id: PropTypes.number
    }).isRequired,
    specific: PropTypes.string,
    id: PropTypes.number.isRequired
  }).isRequired
};

export default NewsCard;
