import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';
import { Badge } from 'react-bootstrap';
import { indexTransactionsRequest } from '../../requests/transactions';
import { RemoteDataTable } from '../../components';

const columns = memoize((nameWord, firstLetter) => [
  {
    name: 'Descripción',
    selector: 'description_name',
    sortable: false,
    grow: '1'
  },
  {
    cell: ({ amount }) => (
      <Badge className="badge-red">
        {amount} {firstLetter}
      </Badge>
    ),
    name: nameWord,
    selector: 'amount',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'Fecha',
    selector: 'created_at',
    sortable: true,
    grow: '2'
  }
  // {
  //   cell: item => (
  //     <BasicActionBtns
  //       item={item}
  //       clickHandler={clickHandler}
  //       withShow={false}
  //       withDestroy={false}
  //       withEdit={false}
  //       editClick={false}
  //       showClick={false}
  //     />
  //   ),
  //   ignoreRowClick: true,
  //   allowOverflow: false,
  //   button: true,
  //   grow: '1',
  //   minWidth: '123px'
  // }
]);

const TransactionDataTable = () => {
  const [onRequest, setOnRequest] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [amount, setAmount] = useState(0);
  const dispatch = useDispatch();
  const {
    settings: { nameWord }
  } = useSelector(state => state.utils);
  const firstLetter = nameWord ? ` ${nameWord[0].toLowerCase()}.` : '';

  const handleSuccessIndex = response => {
    const _transactions = response.data.data;
    const { amount: _amount } = response.data.metadata;
    setTransactions(_transactions);
    setAmount(_amount);
    setOnRequest(false);
  };

  const handleRequest = params => {
    setOnRequest(true);
    indexTransactionsRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex
    });
  };

  // const handleButtonClick = (item, action) => {
  //   switch (action) {
  //     default:
  //       // eslint-disable-next-line no-alert
  //       alert('Error: Action not found');
  //   }
  // };

  const sortColumnCase = name => {
    switch (name) {
      case 'caption':
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <RemoteDataTable
        id="nn-slides-datatable"
        onRequest={onRequest}
        columns={columns(nameWord, firstLetter)}
        handleSortCase={sortColumnCase}
        data={transactions}
        totalRows={amount}
        resourceRequest={handleRequest}
      />
    </>
  );
};

export default withRouter(connect()(TransactionDataTable));
