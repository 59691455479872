import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { history } from '../../config/store';
import { LinkBtn, FormikInput, DefaultBtn, MainLogo } from '../../components';
import { requestSignIn } from '../../actions/auth';

const Login = props => {
  const { onHide, isValid, submitVariant, errors, touched } = props;

  const [redirect, setRedirect] = useState(false);
  const { signedIn } = useSelector(state => state.auth);

  useEffect(() => {
    if (signedIn) setRedirect(true);
  }, [signedIn]);

  if (redirect) history.push('/home');

  return (
    <Col style={{ padding: 0 }}>
      <div className="header" />
      <Row
        className="p-4 d-flex justify-content-center align-items-center"
        md={4}
        style={{ height: 300, margin: 0 }}
      >
        <MainLogo style={{ height: 250 }} className="login-logo" />
      </Row>
      <Row
        className="justify-content-center align-items-center container-login-form"
        style={{ padding: 15, margin: 0 }}
      >
        <Col md={4}>
          <Form id="nn-login-form">
            <Field name="email">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Correo"
                  placeholder="Tucorreo@mail.com"
                  inputType="email"
                  error={errors[field.name]}
                  touched={touched[field.name]}
                />
              )}
            </Field>
            <Field name="password">
              {({ field }) => (
                <FormikInput
                  {...field}
                  inputType="password"
                  label="Clave"
                  placeholder="******"
                  error={errors[field.name]}
                  touched={touched[field.name]}
                />
              )}
            </Field>
            <DefaultBtn
              variant={submitVariant}
              size="lg"
              className="mt-4"
              block
              type="submit"
              disabled={!isValid}
              onClick={onHide}
            >
              INICIAR SESIÓN
            </DefaultBtn>
          </Form>
          <div className="d-flex justify-content-center mt-4">
            <LinkBtn to="/recover_password">¿Olvidaste tu cuenta?</LinkBtn>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  email: '',
  password: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio'),
  password: Yup.string().required('Este campo es obligatorio')
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  dispatch(
    requestSignIn({
      user: {
        email: values.email,
        password: values.password
      }
    })
  );
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(Login)
  )
);
