import React from 'react';
import { useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import Loader from '../Loader';

const MainLogo = ({ className }) => {
  const { settings } = useSelector(state => state.utils);
  const { logo } = settings;

  return !logo ? (
    <Loader minHeight={0} />
  ) : (
    <Image
      src={logo}
      alt="Logo"
      className={`d-inline-block align-top ${className}`}
    />
  );
};

export default MainLogo;
