import React from 'react';
import Select from 'react-select';

const MultiSelect = ({ options, handleOnChange, placeholder, styles }) => (
  <Select
    defaultValue={options}
    isMulti
    name="colors"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
    placeholder={placeholder}
    styles={styles}
    onChange={value => handleOnChange(value)}
  />
);

export default MultiSelect;
