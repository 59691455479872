import ApiService from '../services/apiService';

// eslint-disable-next-line import/prefer-default-export
export const indexTransactionsRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/transactions${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};
