import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import {
  indexChallengesRequest,
  markChallengesAsReadRequest
} from '../../requests/challenge';
import ChallengesList from './ChallengesList';
import { emptyUnreadChallenges } from '../../actions/utils';
import { Slides, Loader } from '../../components';
import { indexSlidesRequest } from '../../requests/slides';

const ChallengeIndex = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [challenges, setChallenges] = useState([]);
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();

  const handleindexChallengesRequest = () => {
    setOnRequest(true);
    indexChallengesRequest({
      dispatch,
      successCallback: result => {
        setChallenges(camelCaseRecursive(result.data.data));
        setOnRequest(false);
      }
    });
  };

  const handleIndexSlidesRequest = () => {
    setOnRequest(true);
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        zone: 1
      },
      successCallback: result => {
        setSlides(camelCaseRecursive(result.data.data));
        setOnRequest(false);
      }
    });
  };

  useEffect(() => {
    handleindexChallengesRequest();
    handleIndexSlidesRequest();

    markChallengesAsReadRequest({
      dispatch
    });

    dispatch(emptyUnreadChallenges());
  }, [dispatch]);

  return onRequest ? (
    <Loader />
  ) : (
    <>
      <Slides
        items={slides}
        carouselStyle={{
          minHeight: 170
        }}
      />
      <h2 id="nn-slides-title" className="screen-title">
        Desafíos
      </h2>
      <ChallengesList challenges={challenges} />
    </>
  );
};

export default withRouter(connect()(ChallengeIndex));
