import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Badge } from 'react-bootstrap';

import { indexRedeemsRequest } from '../../requests/redeems';
import { RemoteDataTable } from '../../components';

const columns = memoize((trakingLink = true, nameWord, firstLetter) => [
  {
    name: 'Producto',
    selector: 'productName',
    sortable: true,
    grow: '1'
  },
  {
    cell: ({ pointsUsed }) => (
      <Badge className="badge-red">
        {pointsUsed.toFixed(1)} {firstLetter}
      </Badge>
    ),
    name: `${nameWord} utilizados`,
    selector: 'pointsUsed',
    sortable: true,
    center: true,
    grow: '1'
  },
  {
    name: 'Link de seguimiento',
    selector: 'trackingLink',
    sortable: false,
    omit: !trakingLink
  },
  {
    name: 'Dirección de despacho',
    selector: 'address',
    sortable: false
  },
  {
    name: 'Fecha',
    selector: 'createdAt',
    sortable: false
  }
  // {
  //   cell: item => (
  //     <BasicActionBtns
  //       item={item}
  //       clickHandler={clickHandler}
  //       withShow={false}
  //       withDestroy={false}
  //       withEdit={false}
  //       editClick={false}
  //       showClick={false}
  //     />
  //   ),
  //   ignoreRowClick: true,
  //   allowOverflow: false,
  //   button: true,
  //   grow: '1',
  //   minWidth: '123px'
  // }
]);

const RedeemsDataTable = ({ trakingLink, searching, title }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [redeems, setRedeems] = useState([]);
  const [amount, setAmount] = useState(0);
  const dispatch = useDispatch();
  const {
    settings: { nameWord }
  } = useSelector(state => state.utils);
  const firstLetter = nameWord ? ` ${nameWord[0].toLowerCase()}.` : '';

  const handleSuccessIndex = response => {
    const _redeems = response.data.data;
    const { amount: _amount } = response.data.metadata;
    setRedeems(camelCaseRecursive(_redeems));
    setAmount(_amount);
    setOnRequest(false);
  };

  const handleRequest = params => {
    setOnRequest(true);
    indexRedeemsRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex
    });
  };

  // const handleButtonClick = (item, action) => {
  //   switch (action) {
  //     default:
  //       // eslint-disable-next-line no-alert
  //       alert('Error: Action not found');
  //   }
  // };

  const sortColumnCase = name => {
    switch (name) {
      case 'caption':
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return redeems.length > 0 || onRequest ? (
    <>
      {!onRequest && <h5 className="screen-title">{title}</h5>}
      <RemoteDataTable
        id="nn-slides-datatable"
        onRequest={onRequest}
        columns={columns(trakingLink, nameWord, firstLetter)}
        handleSortCase={sortColumnCase}
        data={redeems}
        totalRows={amount}
        resourceRequest={handleRequest}
        withSearching={searching}
        showPagination={false}
      />
    </>
  ) : (
    <>
      <h5 className="screen-title">{title}</h5>
      <p>No posees productos canjeados</p>
    </>
  );
};

export default withRouter(connect()(RedeemsDataTable));
