import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col, Card, Image } from 'react-bootstrap';
import { AiFillEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { BasicCard, Loader } from '../../components';
import showMeRequest from '../../requests/auth';
import RedeemsDataTable from '../Redeem/RedeemsDataTable';
// import { indexProductsRequest } from '../../requests/products';
// import Product from '../Product/ProductCard';
import './styles.scss';

const Profile = () => {
  // const [products, setProducts] = useState([]);
  const [user, setUser] = useState({ avatar: [] });
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();
  const {
    settings: {
      nameKpi1,
      nameKpi2,
      nameKpi3,
      nameKpi4,
      nameWord,
      kpi4Available
    }
  } = useSelector(state => state.utils);
  const firstLetter = nameWord ? ` ${nameWord[0].toLowerCase()}.` : '';

  useEffect(() => {
    setOnRequest(true);
    const dispatchGetMe = () => {
      showMeRequest({
        dispatch,
        params: {
          date_from: '',
          date_to: ''
        },
        successCallback: response => {
          if (response.data.logged_in) {
            setUser(camelCaseRecursive(response.data.user_info));
          }
          setOnRequest(false);
        }
      });
    };
    // indexProductsRequest({
    //   dispatch,
    //   successCallback: result => {
    //     setProducts(camelCaseRecursive(result.data.data));
    //   }
    // });

    dispatchGetMe();
  }, [dispatch]);

  const avatarUrl = user.avatar.length ? user.avatar[0].url : '';
  const { monthlyPoints } = user;

  const kpi1 = monthlyPoints ? monthlyPoints.kpi1Percent * 100 : 0;
  const kpi2 = monthlyPoints ? monthlyPoints.kpi2Percent * 100 : 0;
  const kpi3 = monthlyPoints ? monthlyPoints.kpi3Percent * 100 : 0;
  const kpi4 = monthlyPoints ? monthlyPoints.kpi3Percent * 100 : 0;

  return (
    <Row id="nn-profile-wrapper" className="mt-4">
      <Col md={12}>
        {onRequest ? (
          <Loader height="120px" minHeight="auto" />
        ) : (
          <Row style={{ alignItems: 'center' }}>
            <Col xs={12} sm={3} md={2}>
              <Image src={avatarUrl} className="avatar" roundedCircle />
            </Col>
            <Col className="pt-3" xs={12} sm={8} md={8}>
              <h4 style={{ fontWeight: 700 }}>¡Hola {user.fullName}!</h4>
              <p>{user.zoneName}</p>
              <h6 style={{ fontWeight: 700 }}>
                <Link to="/profile/edit" variant="primary">
                  <AiFillEdit /> Editar Perfil
                </Link>
              </h6>
            </Col>
          </Row>
        )}
      </Col>

      <Col md={5}>
        <Row className="mt-5">
          <Col xs={6}>
            <BasicCard
              className="card-secondary-bg basic-card"
              title={`${nameWord} Acumulados`}
              text={user.balance && user.balance.toFixed(1)}
            />
          </Col>
          <Col xs={6}>
            <BasicCard
              className="card-secondary-bg basic-card"
              title={`${nameWord} Canjeados`}
              text={user.redeemedPoints && user.redeemedPoints.toFixed(1)}
            />
          </Col>
        </Row>
        <Row className="mt-3" md={12}>
          <Col md={12}>
            <Card className="card-primary-bg">
              <Card.Body>
                <Row style={{ alignItems: 'center' }}>
                  <Col md={6}>
                    <h4 className="badge-primary">
                      Desafíos realizados del mes
                    </h4>
                  </Col>
                  <Col md={6}>
                    <h4
                      className="mt-3 badge-primary text-center"
                      style={{ fontSize: 28 }}
                    >
                      {user.challengesResume}
                    </h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={12}>
            <h4>{nameWord} del mes</h4>
          </Col>
          <Col xs={6}>
            <Card border="primary" style={{ width: '100%', height: 120 }}>
              <Card.Body>
                <Row>
                  <Col md={12}>
                    <p className="text-center">{nameKpi1}</p>
                  </Col>
                  <Col md={12}>
                    <h4 className="text-center">
                      {monthlyPoints && kpi1 % 1 == 0 ? kpi1 : kpi1.toFixed(1)}%
                    </h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6}>
            <Card border="primary" style={{ width: '100%', height: 120 }}>
              <Card.Body>
                <Row>
                  <Col md={12}>
                    <p className="text-center">{nameKpi2}</p>
                  </Col>
                  <Col md={12}>
                    <h4 className="text-center">
                      {monthlyPoints && kpi2 % 1 == 0 ? kpi2 : kpi2.toFixed(1)}%
                    </h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={6}>
            <Card
              border="primary"
              className="mt-3"
              style={{ width: '100%', height: 120 }}
            >
              <Card.Body>
                <Row>
                  <Col md={12}>
                    <p className="text-center">{nameKpi3}</p>
                  </Col>
                  <Col md={12}>
                    <h4 className="text-center">
                      {monthlyPoints && kpi3 % 1 == 0 ? kpi3 : kpi3.toFixed(1)}%
                    </h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          {kpi4Available > 0 && (
            <Col xs={6}>
              <Card
                border="primary"
                className="mt-3"
                style={{ width: '100%', height: 120 }}
              >
                <Card.Body>
                  <Row>
                    <Col md={12}>
                      <p className="text-center">{nameKpi4}</p>
                    </Col>
                    <Col md={12}>
                      <h4 className="text-center">
                        {monthlyPoints && kpi4 % 1 == 0
                          ? kpi4
                          : kpi4.toFixed(1)}
                        %
                      </h4>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          )}
          <Col xs={kpi4Available > 0 ? 12 : 6}>
            <Card
              border="primary"
              className="mt-3"
              style={{ width: '100%', height: 120 }}
            >
              <Card.Body>
                <Row>
                  <Col md={12}>
                    <p className="text-center">Desafíos</p>
                  </Col>
                  <Col md={12}>
                    <h4 className="text-center">
                      {monthlyPoints && monthlyPoints.challenges} {firstLetter}
                    </h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
      {/* <Col md={7}>
        <h3>Premios favoritos</h3>
        <div className="container testimonial-group">
          <div className="row text-center">
            {products.map(product => (
              <div className="col-5">
                <Product item={product} />
              </div>
            ))}
          </div>
        </div>
      </Col> */}
      <Col md={7} className="mt-5">
        <RedeemsDataTable
          trakingLink={false}
          searching={false}
          title="Premios canjeados"
        />
      </Col>
    </Row>
  );
};

export default connect()(Profile);
