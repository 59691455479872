import React, { useEffect } from 'react';
import { useAbility } from '@casl/react';
import { useDispatch, useSelector } from 'react-redux';
import { validateToken } from '../../../actions/auth';
import PrincipalNavbar from './PrincipalNavbar';
import { NotificationsDropdown, ProfileDropdown } from '../Dropdown';
import { AbilityContext } from '../../../config/abilityContext';
import { updateAbility } from '../../../config/ability';
import { getUnreadChallenges, getUnreadNews } from '../../../actions/utils';

const NavbarLogged = props => {
  const { user } = useSelector(state => state.auth);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const updateAbilityRoles = () => {
    updateAbility(ability, user.role);
  };

  useEffect(() => {
    if (user.id === 0) {
      dispatch(validateToken());
    } else {
      dispatch(getUnreadChallenges());
      dispatch(getUnreadNews());
    }
  }, [dispatch, user.id]);

  useEffect(updateAbilityRoles, [user]);

  const { handleToggleSidebar } = props;
  return (
    <PrincipalNavbar onClick={handleToggleSidebar}>
      <div className="d-flex ml-auto flex-row">
        <NotificationsDropdown />
        <ProfileDropdown loggedIn />
      </div>
    </PrincipalNavbar>
  );
};

export default NavbarLogged;
