import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import ProductInfo from './ProductInfo';
import { BackBtn, Loader } from '../../components';
import { showProductRequest } from '../../requests/products';

const ProductShow = props => {
  const [onRequest, setOnRequest] = useState(false);
  const [product, setProduct] = useState({
    id: '',
    fileInfo: '',
    position: 0,
    caption: '',
    active: ''
  });

  const handleSuccessShow = response => {
    const _product = camelCaseRecursive(response.data);
    setProduct(_product);
  };

  useEffect(() => {
    const { match, dispatch } = props;
    const productId = match.params.id;
    setOnRequest(true)
    showProductRequest(productId, {
      dispatch,
      successCallback: response => {
        handleSuccessShow(response)
        setOnRequest(false)
      }
    });
  }, [props]);

  return onRequest ? <Loader/> : (
    <>
      <Row className="mt-3">
        <Col md={2}>
          <BackBtn route="/products" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={11}>
          <ProductInfo product={product} />
        </Col>
      </Row>
    </>
  );
};

export default connect()(ProductShow);
