import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { MainLogo, NavbarLogged } from '../components';
import { requestSignOut } from '../actions/auth';
import Sidebar from './sidebar';

export const LoggedLayout = ({ component: Component, ...rest }) => {
  const { unreadChallenges, unreadNews } = useSelector(state => state.utils);
  const [toggled, setToggled] = useState(false);
  const dispatch = useDispatch();

  const handleToggleSidebar = () => {
    setToggled(!toggled);
  };
  document.body.style.paddingTop = '60px';
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <NavbarLogged handleToggleSidebar={handleToggleSidebar} />
            <div className="nav-container">
              <Sidebar
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
                onSignOut={() => dispatch(requestSignOut())}
                unreadChallenges={unreadChallenges}
                unreadNews={unreadNews}
              />
              <Container fluid className="layout-container">
                <div style={{ minHeight: 'calc(100vh)' }}>
                  <Component {...props} />
                </div>
              </Container>
            </div>
          </>
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export const NonLoggedLayout = ({ component: Component, ...rest }) => {
  document.body.style.paddingTop = '0%';
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <Redirect
            to={{ pathname: '/home', state: { from: props.location } }}
          />
        ) : (
          <>
            <div>
              <Container fluid style={{ padding: 0, height: 'auto' }}>
                <Component {...props} />
              </Container>
            </div>
          </>
        )
      }
    />
  );
};

export const PublicLayout = ({ component: Component, ...rest }) => {
  const { unreadChallenges, unreadNews } = useSelector(state => state.utils);
  const [toggled, setToggled] = useState(false);
  const dispatch = useDispatch();

  const handleToggleSidebar = () => {
    setToggled(!toggled);
  };

  document.body.style.paddingTop = '0%';

  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <NavbarLogged handleToggleSidebar={handleToggleSidebar} />
            <div className="nav-container">
              <Sidebar
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
                onSignOut={() => dispatch(requestSignOut())}
                unreadChallenges={unreadChallenges}
                unreadNews={unreadNews}
              />
              <Container fluid>
                <Component {...props} />
              </Container>
            </div>
          </>
        ) : (
          <>
            <div className="nav-container">
              <Container fluid>
                <Component {...props} />
              </Container>
            </div>
          </>
        )
      }
    />
  );
};
