/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { BsPlusCircleFill } from 'react-icons/bs';
import defaultImg from '../../../assets/images/default.png';
import './styles.scss';

const UploadImage = props => {
  const [url, setUrl] = useState('');
  const [name, setName] = useState('Elegir Archivo');

  const _handleImageChange = e => {
    const { onChange } = props;
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setUrl(reader.result);
      setName(file.name);
    };
    reader.readAsDataURL(file);
    onChange(file);
  };

  const { imageUrl } = props;
  return (
    <div style={{ textAlign: '-webkit-center' }}>
      <div className=" mb-3">
        <div style={{ width: '200px', height: '200px' }}>
          <Image
            src={url || imageUrl || defaultImg}
            style={{ borderRadius: '100%' }}
            fluid
          />
        </div>
        <div className="custom-file-container">
          <input
            type="file"
            className="custom-file-input secondary"
            id="inputGroupFile01"
            aria-describedby="inputGroupFileAddon01"
            onChange={e => _handleImageChange(e)}
          />
          <label
            className="text-truncate icon-plus-style"
            htmlFor="inputGroupFile01"
          >
            <BsPlusCircleFill style={{ fontSize: 30 }} />
          </label>
        </div>
      </div>
    </div>
  );
};

UploadImage.defaultProps = {
  onChange: () => {}
};

export default UploadImage;
