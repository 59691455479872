import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NotificationDataTable from './NotificationDataTable';

const ChallengeIndex = () => {
  return (
    <>
      <h2 className="screen-title">Notificaciones</h2>

      <div id="nn-notifications-wrapper">
        <NotificationDataTable />
      </div>
    </>
  );
};

export default withRouter(connect()(ChallengeIndex));
