import React from 'react';
import memoize from 'memoize-one';
import { Image, Badge } from 'react-bootstrap';
import defaultImg from '../../assets/images/default.png';

const baseKpis = (isSm, firstLetter) => [
  {
    cell: ({ kpi1Decimal }) => (
      <Badge className="badge-gray">
        Total {kpi1Decimal.toFixed(1)} {firstLetter}
      </Badge>
    ),
    name: 'KPI1',
    selector: 'kpi1',
    sortable: false,
    grow: '2',
    center: true,
    omit: isSm
  },
  {
    cell: ({ kpi2Decimal }) => (
      <Badge className="badge-gray">
        Total {kpi2Decimal.toFixed(1)} {firstLetter}
      </Badge>
    ),
    name: 'KPI2',
    selector: 'kpi2',
    sortable: false,
    grow: '2',
    center: true,
    omit: isSm
  },
  {
    cell: ({ kpi3Decimal }) => (
      <Badge className="badge-gray">
        Total {kpi3Decimal.toFixed(1)} {firstLetter}
      </Badge>
    ),
    name: 'KPI3',
    selector: 'kpi3',
    sortable: false,
    grow: '2',
    center: true,
    omit: isSm
  }
];

const baseChallenges = (isSm, firstLetter) => [
  {
    name: 'Desafíos',
    selector: 'challenges',
    sortable: false,
    grow: '2',
    center: true,
    omit: isSm,
    style: {
      fontWeight: 'bold',
      fontSize: '14px'
    }
  },
  {
    cell: ({ totalPointsDecimal }) => (
      <Badge className="badge-red">
        Total {totalPointsDecimal.toFixed(1)} {firstLetter}
      </Badge>
    ),
    name: 'Total',
    selector: 'totalPoints',
    sortable: true,
    grow: '2',
    center: true
  }
];

const baseRanking = () => [
  {
    name: 'N',
    selector: 'position',
    sortable: true,
    grow: '1',
    center: true,
    compact: true
  },
  {
    cell: ({ user: { avatar } }) => (
      <Image
        src={avatar.length ? avatar[0].url : defaultImg}
        className="table-avatar"
        roundedCircle
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    right: true,
    button: true,
    grow: '1'
  },
  {
    name: 'Nombre',
    selector: 'user.fullName',
    sortable: true,
    grow: '2',
    style: {
      fontSize: 16
    }
  },
  {
    name: 'Zona',
    selector: 'user.zoneName',
    sortable: true,
    grow: '1',
    style: {
      fontSize: 16
    }
  }
];

const rankingColumns = memoize((isSm, firstLetter) => [
  ...baseRanking(),
  ...baseKpis(isSm, firstLetter),
  ...baseChallenges(isSm, firstLetter)
]);

const rankingColumnsKpi4 = memoize((isSm, firstLetter) => [
  ...baseRanking(isSm, firstLetter),
  ...baseKpis(isSm, firstLetter),
  {
    cell: ({ kpi4Decimal }) => (
      <Badge className="badge-gray">
        Total {kpi4Decimal.toFixed(1)} {firstLetter}
      </Badge>
    ),
    name: 'KPI4',
    selector: 'kpi4',
    sortable: false,
    grow: '2',
    center: true,
    omit: isSm
  },
  ...baseChallenges(isSm, firstLetter)
]);

const baseZone = () => [
  {
    name: 'N',
    selector: 'position',
    sortable: true,
    grow: '1',
    center: true,
    compact: true
  },
  {
    name: 'Zona',
    selector: 'zone.name',
    sortable: true,
    grow: '2',
    style: {
      fontSize: 16,
      minWidth: 60
    }
  }
];

const zoneColumns = memoize((isSm, firstLetter) => [
  ...baseZone(),
  ...baseKpis(isSm, firstLetter),
  ...baseChallenges(isSm, firstLetter)
]);

const zoneColumnsKpi4 = memoize((isSm, firstLetter) => [
  ...baseZone(),
  ...baseKpis(isSm, firstLetter),
  {
    cell: ({ kpi4Decimal }) => (
      <Badge className="badge-gray">
        Total {kpi4Decimal.toFixed(1)} {firstLetter}
      </Badge>
    ),
    name: 'KPI4',
    selector: 'kpi4',
    sortable: false,
    grow: '2',
    center: true,
    omit: isSm
  },
  ...baseChallenges(isSm, firstLetter)
]);

export { rankingColumns, rankingColumnsKpi4, zoneColumns, zoneColumnsKpi4 };
