import { takeEvery, put } from 'redux-saga/effects';
import { utilsTypes } from '../actions/utils';
import API from '../services/api';
import runDefaultSaga, { setUserHeaders } from './default';

// Unread Challenges
const getUnreadChallengesRequest = () => API.get('/challenges/unread');

function* getUnreadChallengesSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({
      type: utilsTypes.GET_UNREAD_CHALLENGES_SUCCESS,
      result,
      response
    });
  }
}

function* getUnreadChallengesFailureCallback() {
  yield put({
    type: utilsTypes.GET_UNREAD_CHALLENGES_FAILURE
  });
}

function* getUnreadChallenges() {
  yield runDefaultSaga(
    { request: getUnreadChallengesRequest },
    getUnreadChallengesSuccessCallback,
    getUnreadChallengesFailureCallback
  );
}

// Unread News
const getUnreadNewsRequest = () => API.get('/news/unread');

function* getUnreadNewsSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.GET_UNREAD_NEWS_SUCCESS, result, response });
  }
}

function* getUnreadNewsFailureCallback() {
  yield put({
    type: utilsTypes.GET_UNREAD_NEWS_FAILURE
  });
}

function* getUnreadNews() {
  yield runDefaultSaga(
    { request: getUnreadNewsRequest },
    getUnreadNewsSuccessCallback,
    getUnreadNewsFailureCallback
  );
}

// Settings
const getSettingsRequest = () => API.get('/settings');

function* getSettingsSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.GET_SETTINGS_SUCCESS, result, response });
  }
}

function* getSettingsFailureCallback() {
  yield put({
    type: utilsTypes.GET_SETTINGS_FAILURE
  });
}

function* getSettings() {
  yield runDefaultSaga(
    { request: getSettingsRequest },
    getSettingsSuccessCallback,
    getSettingsFailureCallback
  );
}

// Slides
const getSlidesRequest = () => API.get('/pages');

function* getSlidesSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.GET_SLIDES_SUCCESS, result, response });
  }
}

function* getSlidesFailureCallback() {
  yield put({
    type: utilsTypes.GET_SLIDES_FAILURE
  });
}

function* getSlides() {
  yield runDefaultSaga(
    { request: getSlidesRequest },
    getSlidesSuccessCallback,
    getSlidesFailureCallback
  );
}

// Page
const getPageRequest = url => API.get(`/pages/${url}`);

function* getPageSuccessCallback(result) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.GET_PAGE_SUCCESS, result });
  }
}

function* getPageFailureCallback() {
  yield put({
    type: utilsTypes.GET_PAGE_FAILURE
  });
}

function* getPage(action) {
  yield runDefaultSaga(
    { request: getPageRequest, params: action.url },
    getPageSuccessCallback,
    getPageFailureCallback
  );
}

// Headers
function* setHeaders(headers) {
  yield setUserHeaders(headers);
}

const getLastNotificationsRequest = () =>
  API.get('/notifications?sort_column=id&sort_direction=desc&display_length=5');

function* getLastNotificationsSuccessCallback(result) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.GET_LAST_NOTIFICATIONS_SUCCESS, result });
  }
}

function* getLastNotificationsFailureCallback() {
  yield put({
    type: utilsTypes.GET_LAST_NOTIFICATIONS_FAILURE
  });
}

function* getLastNotifications() {
  yield runDefaultSaga(
    { request: getLastNotificationsRequest },
    getLastNotificationsSuccessCallback,
    getLastNotificationsFailureCallback
  );
}

const setNotificationsReadRequest = () =>
  API.post('/notifications/mark_as_read');

function* setNotificationsReadSuccessCallback(result) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.SET_NOTIFICATIONS_READ_SUCCESS, result });
  }
}

function* setNotificationsReadFailureCallback() {
  yield put({
    type: utilsTypes.SET_NOTIFICATIONS_READ_FAILURE
  });
}

function* setNotificationsRead() {
  yield runDefaultSaga(
    { request: setNotificationsReadRequest },
    setNotificationsReadSuccessCallback,
    setNotificationsReadFailureCallback
  );
}

const getUnreadNotificationsRequest = () =>
  API.get('/notifications?unread=true');

function* getUnreadNotificationsSuccessCallback(result) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS, result });
  }
}

function* getUnreadNotificationsFailureCallback() {
  yield put({
    type: utilsTypes.GET_UNREAD_NOTIFICATIONS_FAILURE
  });
}

function* getUnreadNotifications() {
  yield runDefaultSaga(
    { request: getUnreadNotificationsRequest },
    getUnreadNotificationsSuccessCallback,
    getUnreadNotificationsFailureCallback
  );
}

// DEFINE utilsSagas
export default function* utilsSagas() {
  yield takeEvery(
    utilsTypes.GET_UNREAD_CHALLENGES_REQUEST,
    getUnreadChallenges
  );
  yield takeEvery(utilsTypes.GET_UNREAD_NEWS_REQUEST, getUnreadNews);
  yield takeEvery(utilsTypes.GET_SETTINGS_REQUEST, getSettings);
  yield takeEvery(utilsTypes.GET_SLIDES_REQUEST, getSlides);
  yield takeEvery(utilsTypes.GET_PAGE_REQUEST, getPage);
  yield takeEvery(utilsTypes.SET_HEADERS, setHeaders);
  yield takeEvery(
    utilsTypes.GET_LAST_NOTIFICATIONS_REQUEST,
    getLastNotifications
  );
  yield takeEvery(
    utilsTypes.SET_NOTIFICATIONS_READ_REQUEST,
    setNotificationsRead
  );
  yield takeEvery(
    utilsTypes.GET_UNREAD_NOTIFICATIONS_REQUEST,
    getUnreadNotifications
  );
}
