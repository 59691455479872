import React from 'react';
import { withFormik, Form, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import UserForm from './UserForm';

const ProfileForm = props => {
  const {
    onHide,
    submitVariant,
    errors,
    touched,
    action,
    setFieldValue,
    fromShow,
    user
  } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';

  return (
    <Form>
      <Row className="mt-3 mb-4">
        <UserForm
          action={action}
          user={user}
          errors={errors}
          setFieldValue={setFieldValue}
          touched={touched}
          fromShow={fromShow}
        />
      </Row>

      {!fromShow && (
        <Row className="d-flex mb-4">
          <Col xs={{ span: 8, offset: 2 }} style={{ textAlign: 'right' }}>
            <Button type="submit" variant={submitVariant} onClick={onHide}>
              {btnMessage}
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};

const setInitialValues = ({ user }) => {
  const { id, avatarInfo, email, fullName, address } = user;

  return {
    user: {
      id,
      avatar: getIn(avatarInfo, 'fileSignature'),
      email,
      fullName,
      address
    }
  };
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    fullName: Yup.string().nullable()
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProfileForm);
