const baseColors = {
  primary: "#07326B",
  secondary: "#B6D0F5",
  red: "#dc3545",
  primaryBg: "#F8F8F8",

  // datatable
  bgDatatable: "#F8F8F8",
  colorDatatable: "null",

  // select
  bgSelect: "#F8F8F8",
  bgMultiValueSelect: "#0152c0",
  hoverBgMultiValueRemoveSelect: "#0152c0",
  hoverTextMultiValueRemoveSelect: "#f2e6dd",

  // text points product
  colorTextPointsProducts: "#07326B",

  gray100: "#f8f9fa",
  gray200: "#e9ecef",
  gray300: "#dee2e6",
  gray400: "#ced4da",
  gray500: "#adb5bd",
  gray600: "#6c757d",
  gray700: "#495057",
  gray800: "#343a40",
  gray900: "#282828",
};

export default baseColors;
