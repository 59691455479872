/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Edit } from 'react-feather';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { Row, Col } from 'react-bootstrap';
import { BackBtn } from '../../components';
import ProfileForm from './ProfileForm';
import {
  showUserRequest,
  updateUserRequest,
  deleteDocumentRequest
} from '../../requests/user';
import { sendAlert } from '../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../services/utils';

const ProfileEdit = props => {
  const [user, setUser] = useState({ email: '', fullName: '', avatarInfo: {} });
  const [documentCode, setDocumentCode] = useState(Date.now());
  const [maxAvatarSize, setMaxAvatarSize] = useState(20971520);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFailureRequest = error => {
    dispatch(
      sendAlert({ kind: 'error', message: error?.response?.data?.message })
    );
  };

  useEffect(() => {
    const handleSuccessShow = response => {
      const oldUser = user;
      const newUser = response.data;

      setUser(camelCaseEmptyStringRecursive(newUser));
    };

    showUserRequest({
      dispatch,
      params: {
        date_from: '',
        date_to: ''
      },
      successCallback: response => handleSuccessShow(response)
    });
  }, [dispatch]);

  const updateUserAvatar = documents => {
    setUser({
      ...user,
      currentAvatar: [...documents],
      persistedAvatar: [...user.persistedAvatar]
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Perfil actualizado correctamente'
      })
    );
    history.push('/profile');
  };

  const handleUpdateRequest = values => {
    const { avatar } = values.user;
    const sendParams = snakeCaseKeys(values);
    sendParams.user.avatar = avatar;

    updateUserRequest(user.id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessDestroyDocument = response => {
    const documentInfo = JSON.parse(response.config.data);
    const { persistedAvatar } = user;
    const updatedPersistedAvatar = persistedAvatar.filter(
      avatar => avatar.id !== documentInfo.document_id
    );
    setUser({
      ...user,
      persistedAvatar: updatedPersistedAvatar
    });
  };

  const destroyDocumentRequest = document => {
    deleteDocumentRequest({
      dispatch,
      params: snakeCaseKeys(document),
      formData: true,
      successCallback: response => handleSuccessDestroyDocument(response)
    });
  };

  return (
    <Row id="nn-profile-edit-wrapper">
      <Col md={12}>
        <BackBtn route="/profile" />
        <h4 id="nn-dashboard-title" className="screen-title">
          Editar Perfil
        </h4>
      </Col>
      <Col md={12}>
        <ProfileForm
          user={user}
          action="edit"
          submitVariant="success"
          formRequest={handleUpdateRequest}
          fromEdit
        />
      </Col>
    </Row>
  );
};

export default connect()(ProfileEdit);
