/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { BsPeopleCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { requestSignOut } from '../../../actions/auth';

const ProfileDropdown = ({ loggedIn }) => {
  const dispatch = useDispatch();
  const {
    settings: { nameWord }
  } = useSelector(state => state.utils);

  return (
    <Dropdown alignRight>
      <Dropdown.Toggle variant="circle-dark" id="dropdown-basic">
        <BsPeopleCircle style={{ color: '#b5313a' }} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="t-r-0 mobile">
        {loggedIn ? (
          <>
            <div className="profile-dropdown-link">
              <Link className="ml-3" to="/profile">
                Perfil
              </Link>
            </div>
            <br />
            <div className="profile-dropdown-link">
              <Link className="ml-3" to="/points-summary">
                Mis {nameWord}
              </Link>
            </div>
            <br />
            <div className="profile-dropdown-link">
              <Link className="ml-3" to="/change_password">
                Cambiar contraseña
              </Link>
            </div>
            <br />
            <div className="profile-dropdown-link">
              <Link
                className="ml-3"
                to="#"
                onClick={() => dispatch(requestSignOut())}
              >
                Cerrar sesión
              </Link>
            </div>
          </>
        ) : (
          <>
            <Link className="ml-3" to="/login">
              Iniciar sesión
            </Link>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default connect()(ProfileDropdown);
